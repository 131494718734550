.root {
  &[data-variant='light'] {
    background-color: white;
    padding: 0.25rem;
    color: var(--mantine-color-cavela-shadow-10);
    border-radius: 6px;
    box-shadow: 0 2px 8px var(--mantine-color-cavela-shadow-1);
    transition: all 0.2s ease;
  }

  &[data-variant='light'][data-error='true'] {
    border: 1px solid red;
  }

  &[data-variant='light'][data-error='true'] input {
    border: none;
    border-radius: 0;
  }

  &[data-variant='light']:hover {
    box-shadow: 0 2px 8px var(--mantine-color-cavela-shadow-3);
  }
}
