.root {
  &[data-variant='link-light'] {
    color: var(--mantine-color-cavela-primary-8);
    text-decoration: underline;
    cursor: pointer;
  }
  &[data-variant='link-dark'] {
    color: var(--mantine-color-cavela-primary-0);
    text-decoration: underline;
    cursor: pointer;
  }
}
