a {
  color: var(--mantine-color-cavela-primary-5);
  font-weight: 400;
}

a.customAnchor {
  font-weight: 400;
  text-decoration: underline;
}

a.customAnchor,
a.customAnchor > span span {
  color: var(--mantine-color-cavela-primary-8);
}

a.customAnchor,
a.customAnchor > span span.urgent {
  color: red;
}
