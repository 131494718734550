.root {
  margin-left: var(--mantine-spacing-lg);
  margin-bottom: var(--mantine-spacing-xs);
  margin-top: var(--mantine-spacing-xs);
  padding-bottom: var(--mantine-spacing-xs);
  padding-left: var(--mantine-spacing-lg);
  padding-top: var(--mantine-spacing-xs);
}

/**
 * Mantine override
 * Keep indentation but remove stacking 
 * backgrounds and borders 
 */

blockquote blockquote {
  background: transparent !important;
  border: none !important;
}
