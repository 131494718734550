@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --cavela-primary: #000eef;
  --cavela-secondary: #000000;
  --cavela-caution: #f59e0b;
  --cavela-success: #2cd673;
  --cavela-error: #f23030;
  --cavela-inactive: #d3d5d9;
  --cavela-grey: #6e747d;
  --cavela-background: #f3f4f6;
}

html,
body {
  background: var(--cavela-background);
}

.animate-fadeIn {
  animation: fadeIn 0.8s ease-in-out both;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.animate-openMenu {
  animation: openMenu 0.5s ease-in-out both;
}

@keyframes openMenu {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}
.typewriter {
  overflow: hidden;
  white-space: nowrap;
  animation: typing 1.5s steps(30, end) forwards; /* Hacemos que la animación sea más rápida */
  position: relative;
}

.typewriter::after {
  content: '';
  border-right: 2px solid black; /* Cursor estilo máquina de escribir */
  position: absolute;
  right: 0;
  animation: blink-caret 0.75s step-end infinite;
}

/* Animación para la escritura */
@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

/* Animación para el parpadeo del cursor */
@keyframes blink-caret {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-color: black;
  }
}

/* Para ocultar el cursor al finalizar la animación */
.typewriter.finished::after {
  content: none;
}
