.root {
  border-radius: 100vw;
  transition: all 0.2s ease;

  &:disabled,
  &[data-disabled] {
    border-color: light-dark(
      var(--mantine-color-cavela-shadow-3),
      var(--mantine-color-dark-4)
    );
    background-color: var(--mantine-color-cavela-shadow-2);
    color: var(--mantine-color-cavela-shadow-5);
  }
  &[data-variant='text-only'] {
    background-color: transparent;
    border: none;
    color: var(--mantine-color-cavela-primary-8);
    text-decoration: underline;
  }

  &[data-variant='navbar'] {
    background-color: transparent;
    border: none;
    color: var(--mantine-color-cavela-shadow-0);
  }
  &[data-variant='navbar']:hover {
    border: none;
    background-color: var(--mantine-color-cavela-primary-8);
  }

  &[data-variant='approve'],
  &[data-variant='reject'] {
    border-radius: 100vw;
    background-color: var(--mantine-color-cavela-primary-5);
    border: 1px solid var(--mantine-color-cavela-primary-5);
    color: white;
  }

  &[data-variant='approve']:hover,
  &[data-variant='reject']:hover {
    opacity: 0.75;
  }

  &[data-variant='approve']:hover *,
  &[data-variant='reject']:hover * {
    color: 1px solid var(--mantine-color-cavela-primary-5);
  }

  &[data-variant='reject'] {
    background-color: var(--mantine-color-cavela-shadow-5);
    border: 1px solid var(--mantine-color-cavela-shadow-5);
  }

  &[data-variant='cancel'] {
    background-color: var(--mantine-color-cavela-shadow-0);
    border: 1px solid var(--mantine-color-cavela-shadow-5);
    color: var(--mantine-color-cavela-shadow-5);
  }

  &[data-variant='cancel']:hover {
    background-color: var(--mantine-color-cavela-shadow-0);
    border: 1px solid var(--mantine-color-cavela-shadow-7);
    color: var(--mantine-color-cavela-shadow-7);
  }

  &[data-variant='text-only']:hover {
    background-color: var(--mantine-color-cavela-primary-1);
  }
  &[data-variant='secondary'] {
    background-color: var(--mantine-color-cavela-primary-1);
    border: none;
    color: var(--mantine-color-cavela-primary-8);
  }
  &[data-variant='secondary']:hover {
    background-color: var(--mantine-color-cavela-primary-2);
  }

  &[data-variant='light'] {
    border-radius: 100vw;
    background-color: white;
    color: var(--mantine-color-cavela-primary-6);
    box-shadow: 0 2px 8px var(--mantine-color-cavela-shadow-3);
    transition: all 0.2s ease;
  }

  &[data-variant='light']:hover {
    background-color: var(--mantine-color-cavela-primary-6);
    color: white;
  }
}
