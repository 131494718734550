.fileItemWrapper {
  border-radius: 6px;
  box-shadow: 0 2px 8px var(--mantine-color-cavela-shadow-3);
  transition: all 0.2s ease;
  width: 100%;
  max-width: 100%;
  word-break: break-all;
}

.iconWrapper {
  width: 40px;
  height: 40px;
  min-width: 40px;
  max-width: 40px;
  background-color: var(--mantine-color-cavela-success-1);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.iconWrapperUpload {
  composes: iconWrapper;
  background-color: var(--mantine-color-cavela-shadow-2);
}
