.uploadDrag {
  width: 100%;
  margin: 0 0;
  background-color: var(--mantine-color-cavela-primary-0);
  border-radius: 8px;
  color: var(--mantine-color-cavela-primary-9);
  border: none;
  min-height: 150px;
  align-items: center;
  justify-content: center;
  flex-flow: column;
}

.uploadDrop {
  background-color: var(--mantine-color-cavela-primary-1);
  cursor: -moz-grabbing;
  transition: background-color 0.2s;
}
