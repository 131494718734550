.stepsCounterItem {
  border-radius: 50%;
  background-color: var(--mantine-color-cavela-shadow-3);
  width: 15px;
  height: 15px;
}

.stepsCounterItem.activeStep {
  background-color: var(--mantine-color-cavela-primary-7);
}
