.logoWrapper.vertical {
  height: 100%;
  display: flex;
}
.logoWrapper.vertical img {
  height: 100%;
  object-fit: contain;
}

.logoWrapper.horizontal {
  width: 100%;
  display: flex;
}
.logoWrapper.horizontal img {
  width: 100%;
  height: auto;
  object-fit: contain;
}
