:root {
  --grid-cell-size: 5rem;
}

.grid {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 1.2rem;
  margin: 8px 0 0 0;
}

.thumbnail {
  width: var(--grid-cell-size);
  height: calc(var(--grid-cell-size) * 0.667);
  object-fit: cover;
}

.thumbnailWrapper {
  border-radius: 6px;
  overflow: hidden;
  position: relative;
  border: 1px solid #d3d5d9;
}

.thumbnailWrapper,
.thumbnailWrapper img {
  transition: all 0.2s ease;
}

.thumbnailWrapper:hover {
  cursor: pointer;
  transform: scale(1.1);
}

.thumbnailWrapper:hover img {
  filter: none;
}

.modalImage {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.closeButton {
  stroke: white;
}

.closeButton:hover {
  stroke: #000000;
}

.control {
  width: 50px;
  height: 50px;
  background-color: #000000 !important;
  border: 3px solid white !important;
  fill: white;
  stroke: white;
  outline: none;
  &[data-inactive='true'] {
    opacity: 0 !important;
    cursor: default !important;
  }
}

.root {
  background-color: #000000;
}
