.notFoundWrapper {
  flex: 1;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.detailsContainer {
  flex: 1;
  background-color: var(--mantine-color-cavela-shadow-0);
  padding: var(--mantine-spacing-xl) var(--mantine-spacing-lg);
  border-radius: var(--mantine-radius-sm);
  box-shadow: 0 2px 8px var(--mantine-color-cavela-shadow-2);
  max-width: 100%;
}
