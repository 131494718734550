.markdown img {
  --img-spacing: 0.5rem;
  padding: var(--img-spacing);
  margin: var(--img-spacing);
  border: 1px solid var(--mantine-color-primary-5);
  cursor: pointer;
  max-width: 90%;
  max-height: 14rem;
}

.markdown p:has(img) {
  display: inline-block;
}

.markdown p {
  margin: 0;
}

:root {
  --quote-margin-left: 2.5rem;
}

.markdown blockquote::before {
  content: '“';
  font-size: 6rem;
  color: rgba(0, 0, 0, 0.1);
  position: absolute;
  z-index: 1;
  top: calc(-1 * var(--quote-margin-left));
  left: calc(-1 * var(--quote-margin-left));
}

.markdown blockquote {
  z-index: 2; /* Put it behind the content */
  position: relative;
  display: block;
  margin-block-start: 1rem;
  margin-block-end: 1rem;
  margin-inline-start: var(--quote-margin-left);
  margin-inline-end: var(--quote-margin-left);
}

.markdown a {
  color: var(--mantine-color-cavela-primary-5);
  text-decoration: none;
  border-bottom-width: 1px;
  border-bottom-style: dashed;
}
