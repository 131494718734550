.root {
  &[data-variant='light'] {
    background-color: white;
    color: var(--mantine-color-cavela-primary-6);
    border-radius: 100vw;
    box-shadow: 0 2px 8px var(--mantine-color-cavela-shadow-3);
    transition: all 0.2s ease;
  }

  &[data-variant='light']:hover {
    background-color: var(--mantine-color-cavela-primary-6);
    color: white;
  }
}
