.markdown > * {
  all: revert;
}

.markdown h1 {
  @apply text-h1 font-700;
}

.markdown h2 {
  @apply text-h2 font-700;
}

.markdown h3 {
  @apply text-h3 font-700;
}

.markdown h4 {
  @apply text-h4 font-700;
}

.markdown p {
  @apply text-base;
}
